export const orgList = [
    {
        activeTab: '1',
        img: 'user/user.png',
        name: 'Mark jecno',
        email: 'markjecno@gmail.com',
        gender: 'Male'
    },
    {
        activeTab: '2',
        img: 'user/3.jpg',
        name: 'Jason Borne',
        email: 'jasonb@gmail.com',
        gender: 'Male'
    },
    {
        activeTab: '3',
        img: 'user/4.jpg',
        name: 'Sarah Loren',
        email: 'barnes@gmail.com',
        gender: 'Female'
    },
    {
        activeTab: '4',
        img: 'user/10.jpg',
        name: 'Andew Jon',
        email: 'andewjon@gmail.com',
        gender: 'Female'
    }
]