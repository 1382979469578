import { AlertCircle, AlertTriangle, Bell, Heart, HelpCircle, ThumbsDown, ThumbsUp } from "react-feather";



export const ThemeColorAlertData = [
    {
        id: 1,
        colorClass: 'primary dark',
        pera: 'This is a primary alert—check it out!'
    },
    {
        id: 2,
        colorClass: 'secondary dark',
        pera: 'This is a secondary alert—check it out!'
    },
    {
        id: 3,
        colorClass: 'success dark',
        pera: 'This is a success alert—check it out!'
    },
    {
        id: 4,
        colorClass: 'info dark',
        pera: 'This is a info alert—check it out!'
    },
    {
        id: 5,
        colorClass: 'warning dark',
        pera: 'This is a warning alert—check it out!'
    },
    {
        id: 6,
        colorClass: 'danger dark',
        pera: 'This is a danger alert—check it out!'
    },
    {
        id: 7,
        colorClass: 'light dark',
        pera: 'This is a light alert—check it out!'
    },
    {
        id: 8,
        colorClass: 'dark dark',
        pera: 'This is a dark alert—check it out!'
    }
]

export const LightAlertData = [
    {
        id: 1,
        colorClass: 'primary',
        pera: 'This is a primary alert—check it out!'
    },
    {
        id: 2,
        colorClass: 'secondary',
        pera: 'This is a secondary alert—check it out!'
    },
    {
        id: 3,
        colorClass: 'success',
        pera: 'This is a success alert—check it out!'
    },
    {
        id: 4,
        colorClass: 'info',
        pera: 'This is a info alert—check it out!'
    },
    {
        id: 5,
        colorClass: 'warning',
        pera: 'This is a warning alert—check it out!'
    },
    {
        id: 6,
        colorClass: 'danger',
        pera: 'This is a danger alert—check it out!'
    },
    {
        id: 7,
        colorClass: 'light',
        pera: 'This is a light alert—check it out!'
    },
    {
        id: 8,
        colorClass: 'dark',
        pera: 'This is a dark alert—check it out!'
    }
]

export const AlertDarkData = [
    {
        id: 1,
        alerttxt: '<strong>Holy ! </strong> You can check in on some of those fields below.',
        alertcolor: 'secondary dark',
        btnclose: 'default'
    },
    {
        id: 2,
        alerttxt: '<strong>Holy ! </strong> You can check in on some of those fields below.',
        alertcolor: 'success dark',
        btnclose: 'default'
    },
    {
        id: 3,
        alerttxt: '<strong>Holy ! </strong> You can check in on some of those fields below.',
        alertcolor: 'info dark',
        btnclose: 'default'
    },
    {
        id: 4,
        alerttxt: '<strong>Holy ! </strong> You can check in on some of those fields below.',
        alertcolor: 'warning dark',
        btnclose: 'default'
    },
    {
        id: 5,
        alerttxt: '<strong>Holy ! </strong> You can check in on some of those fields below.',
        alertcolor: 'danger dark',
        btnclose: 'default'
    },
    {
        id: 6,
        alerttxt: '<strong>Holy ! </strong>You can check in on some of those fields below.',
        alertcolor: 'light dark',
        btnclose: 'default'
    },
    {
        id: 7,
        alerttxt: '<strong>Holy ! </strong>You can check in on some of those fields below.',
        alertcolor: 'dark dark',
        btnclose: 'default'
    }
];

export const dataWithIcon = [
    {
        id: 1,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'secondary dark',
        btnclose: 'default',
        icon: <Heart />
    },
    {
        id: 2,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'success dark',
        btnclose: 'default',
        icon: <ThumbsUp />
    },
    {
        id: 3,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'info dark',
        btnclose: 'default',
        icon: <HelpCircle />
    },
    {
        id: 4,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'warning dark',
        btnclose: 'default',
        icon: <Bell />
    },
    {
        id: 5,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'danger dark',
        btnclose: 'default',
        icon: <ThumbsDown />
    },
    {
        id: 6,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'light dark',
        btnclose: 'default',
        icon: <AlertTriangle />
    },
    {
        id: 7,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'dark dark',
        btnclose: 'default',
        icon: <AlertCircle />
    }
];

export const AlertWithLightDismissData = [
    {
        id: 1,
        alerttxt: '<strong>Holy ! </strong> You can check in on some of those fields below.',
        alertcolor: 'secondary',
        btnclose: 'default'
    },
    {
        id: 2,
        alerttxt: '<strong>Holy ! </strong> You can check in on some of those fields below.',
        alertcolor: 'success',
        btnclose: 'default'
    },
    {
        id: 3,
        alerttxt: '<strong>Holy ! </strong> You can check in on some of those fields below.',
        alertcolor: 'info',
        btnclose: 'default'
    },
    {
        id: 4,
        alerttxt: '<strong>Holy ! </strong> You can check in on some of those fields below.',
        alertcolor: 'warning',
        btnclose: 'default'
    },
    {
        id: 5,
        alerttxt: '<strong>Holy ! </strong> You can check in on some of those fields below.',
        alertcolor: 'danger',
        btnclose: 'default'
    },
    {
        id: 6,
        alerttxt: '<strong>Holy ! </strong>You can check in on some of those fields below.',
        alertcolor: 'light',
        btnclose: 'default'
    },
    {
        id: 7,
        alerttxt: '<strong>Holy ! </strong>You can check in on some of those fields below.',
        alertcolor: 'dark',
        btnclose: 'default'
    }
];

export const IconsWithOutlineData = [
    {
        id: 1,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'secondary outline',
        btnclose: 'default',
        icon: <Heart />
    },
    {
        id: 2,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'success outline',
        btnclose: 'default',
        icon: <ThumbsUp />
    },
    {
        id: 3,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'info outline',
        btnclose: 'default',
        icon: <HelpCircle />
    },
    {
        id: 4,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'warning outline',
        btnclose: 'default',
        icon: <Bell />
    },
    {
        id: 5,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'danger outline',
        btnclose: 'default',
        icon: <ThumbsDown />
    },
    {
        id: 6,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'light outline',
        btnclose: 'default',
        icon: <AlertTriangle />
    },
    {
        id: 7,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'dark dark',
        btnclose: 'default',
        icon: <AlertCircle />
    }
];

export const IconInverseData = [
    {
        id: 1,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'secondary inverse',
        btnclose: 'default',
        icon: <i className="icon-heart"></i>
    },
    {
        id: 2,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'success inverse',
        btnclose: 'default',
        icon: <i className="icon-thumb-up alert-center"></i>,
        boldtxt: 'Well Done!'
    },
    {
        id: 3,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'info inverse',
        btnclose: 'default',
        icon: <i className="icon-help-alt"></i>,
        boldtxt: 'Welcome!'
    },
    {
        id: 4,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'warning inverse',
        btnclose: 'default',
        icon: <i className="icon-bell"></i>,
        boldtxt: 'Congratulation!'

    },
    {
        id: 5,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'danger inverse',
        btnclose: 'default',
        icon: <i className="icon-thumb-down"></i>,
        txt: 'Your',
        boldtxt: 'Perfomance'
    },
    {
        id: 6,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'light outline inverse',
        btnclose: 'default',
        icon: <i className="icon-alert txt-dark"></i>
    },
    {
        id: 7,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'dark inverse',
        btnclose: 'default',
        icon: <i className="icon-info-alt"></i>
    }
];

export const TextAsActionsData = [
    {
        id: 1,
        alertcolor: 'secondary  inverse alert-dismissible',
        alerttxt: 'Oh snap! Change a few things up and submit again.',
        btnClose: 'close',
        icon: <i className="icon-heart"></i>,
        divCls: 'bg-secondary',
        btnContent: '<span className="bg-secondary" >dismiss</span>'
    },
    {
        id: 2,
        alertcolor: 'success inverse alert-dismissible',
        alerttxt: 'Your time Over after <b> 5 </b> miniute',
        btnClose: 'close',
        icon: <i className="icon-thumb-up alert-center"></i>,
        divCls: 'bg-success',
        btnContent: '<span className="bg-success">dismiss</span>'
    },
    {
        id: 3,
        alertcolor: 'info inverse alert-dismissible',
        alerttxt: '<b>Welcome!</b> Start your day with some alerts.',
        btnClose: 'close',
        icon: <i className="icon-help-alt"></i>,
        divCls: 'bg-info',
        btnContent: '<span className="bg-primary ">dismiss</span>'
    },
    {
        id: 4,
        alertcolor: 'warning inverse alert-dismissible',
        alerttxt: '<b> Congratulation!</b>Your Product Added in Like List',
        btnClose: 'close',
        icon: <i className="icon-bell"></i>,
        divCls: 'bg-warning',
        btnContent: '<span className="bg-warning ">dismiss</span>'
    },
    {
        id: 5,
        alertcolor: 'danger inverse alert-dismissible',
        alerttxt: 'Your <b> Perfomance </b> is down on this week',
        icon: <i className="icon-timer"></i>,
        divCls: 'bg-danger',
        btnContent: '<span className="bg-danger ">dismiss</span>'
    },
    {
        id: 6,
        alertcolor: 'light inverse alert-dismissible',
        alerttxt: 'You can check in on some of those fields below.',
        icon: <i className="icon-alert txt-dark"></i>,
        divCls: 'bg-light text-dark',
        btnContent: '<span className="bg-light txt-dark">dismiss</span>'
    },
    {
        id: 7,
        alertcolor: 'dark inverse alert-dismissible',
        alerttxt: 'You can check in on some of those fields below.',
        icon: <i className="icon-info-alt"></i>,
        divCls: 'bg-dark',
        btnContent: '<span className="bg-dark txt-dark ">dismiss</span>'
    }
];

export const BasicPopoverData = [
    {
        id: 2,
        Popoverheader: 'Popover title',
        Popoverbody: 'And heres some amazing content. Its very engaging. Right?',
        btncolor: 'primary',
        btntext: 'Dismissible popover'
    },
    {
        id: 3,
        placement: 'bottom',
        Popoverheader: 'Popover title',
        Popoverbody: 'And heres some amazing content. Its very engaging. Right?',
        btncolor: 'primary',
        btntext: 'On Hover Tooltip',
        trigger: 'hover'
    },
];

export const DirectionData = [
    {
        id: 5,
        placement: 'right',
        Popoverbody: 'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.',
        btncolor: 'primary',
        btntext: 'Popover on right'
    },
    {
        id: 6,
        placement: 'bottom',
        Popoverbody: 'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.',
        btncolor: 'primary',
        btntext: 'Popover on bottom'
    },
    {
        id: 7,
        placement: 'left',
        Popoverbody: 'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.',
        btncolor: 'primary',
        btntext: 'Popover on left'
    },
];

export const OffsetPopoverData = [
    {
        id: 13,
        placement: 'top',
        Popoverheader: 'Popover title',
        Popoverbody: 'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.',
        btncolor: 'primary',
        btntext: '0px',
        offset: [0]
    },
    {
        id: 14,
        placement: 'top',
        Popoverheader: 'Popover title',
        Popoverbody: 'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.',
        btncolor: 'primary',
        btntext: '20px 20px On Hover',
        offset: [20,20],
        trigger: 'hover'

    },
    {
        id: 15,
        placement: 'top',
        Popoverheader: 'Popover title',
        Popoverbody: 'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.',
        btncolor: 'primary',
        btntext: '0px On Hover',
        offset: [0],
        trigger: 'hover'

    },
];

export const BasictooltipData = [
    {
      id: 2,
      placement: 'right',
      tooltip: 'Tooltip on right'
    },
    {
      id: 3,
      placement: 'bottom',
      tooltip: 'Tooltip on bottom'
    },
    {
      id: 4,
      placement: 'left',
      tooltip: 'Tooltip on left'
    }
  ];

export const HtmltooltipData = [    
    {
      id: 6,
      placement: 'right',
      btntext: 'Tooltip on Right',
      tooltip: <>{'Tooltip'} <b>with</b> <code>HTML</code></>
    },
    {
      id: 7,
      placement: 'bottom',
      btntext: 'Tooltip on Bottom',
      tooltip: <>{'Tooltip'} <b>with</b> <code>HTML</code></>
    },
    {
      id: 8,
      placement: 'left',
      btntext: 'Tooltip on Left',
      tooltip: <>{'Tooltip'} <b>with</b> <code>HTML</code></>
    }
];

export const OffsetTooltipData = [
    {
      id: 10,
      placement: 'top',
      tooltip: 'Tooltip title',
      btntext: '0px 20px',
      offset: [0,20]
    },
    {
      id: 11,
      placement: 'top',
      tooltip: 'Tooltip title',
      btntext: '20px 0px',
      offset: [20,0]
    },
    {
      id: 12,
      placement: 'top',
      tooltip: 'Tooltip title',
      btntext: '-30px 30px',
      offset: [-30,30]
    }
];

export const Data = [
    {
        id: 1,
        heading: 'Loader 2',
        spinnerClass: 'loader-2'
    },
    {
        id: 2,
        heading: 'Loader 3',
        spinnerClass: 'loader-3'
    },
    {
        id: 3,
        heading: 'Loader 4',
        spinnerClass: 'loader-4'
    },
    {
        id: 4,
        heading: 'Loader 5',
        spinnerClass: 'loader-5'
    },
    {
        id: 5,
        heading: 'Loader 6',
        spinnerClass: 'loader-6'
    },
    {
        id: 6,
        heading: 'Loader 7',
        spinnerClass: 'loader-7'
    },
    {
        id: 7,
        heading: 'Loader 8',
        spinnerClass: 'loader-8'
    },
    {
        id: 8,
        heading: 'Loader 9',
        spinnerClass: 'loader-9'
    },
    {
        id: 9,
        heading: 'Loader 10',
        spinnerClass: 'loader-10'
    },
    {
        id: 10,
        heading: 'Loader 11',
        spinnerClass: 'loader-11'
    },
    {
        id: 11,
        heading: 'Loader 12',
        spinnerClass: 'loader-12'
    },
    {
        id: 12,
        heading: 'Loader 13',
        spinnerClass: 'loader-13'
    },
    {
        id: 13,
        heading: 'Loader 14',
        spinnerClass: 'loader-14'
    },
    {
        id: 14,
        heading: 'Loader 15',
        spinnerClass: 'loader-15'
    },
    {
        id: 15,
        heading: 'Loader 16',
        spinnerClass: 'loader-40'
    },
    {
        id: 16,
        heading: 'Loader 17',
        spinnerClass: 'loader-17'
    },
    {
        id: 17,
        heading: 'Loader 18',
        spinnerClass: 'loader-18'
    },
    {
        id: 18,
        heading: 'Loader 19',
        spinnerClass: 'loader-19'
    },
    {
        id: 19,
        heading: 'Loader 20',
        spinnerClass: 'loader-20'
    },
    {
        id: 20,
        heading: 'Loader 21',
        spinnerClass: 'loader-21'
    },
    {
        id: 21,
        heading: 'Loader 22',
        spinnerClass: 'loader-22'
    },
    {
        id: 22,
        heading: 'Loader 23',
        spinnerClass: 'loader-23'
    },
    {
        id: 23,
        heading: 'Loader 24',
        spinnerClass: 'loader-24'
    },
    {
        id: 24,
        heading: 'Loader 25',
        spinnerClass: 'loader-25'
    },
    {
        id: 25,
        heading: 'Loader 26',
        spinnerClass: 'loader-26'
    },
    {
        id: 26,
        heading: 'Loader 27',
        spinnerClass: 'loader-29'
    },
    {
        id: 27,
        heading: 'Loader 28',
        spinnerClass: 'loader-30'
    },
    {
        id: 28,
        heading: 'Loader 29',
        spinnerClass: 'loader-31'
    },
    {
        id: 29,
        heading: 'Loader 30',
        spinnerClass: 'loader-32'
    },
    {
        id: 30,
        heading: 'Loader 31',
        spinnerClass: 'loader-34'
    },
    {
        id: 31,
        heading: 'Loader 32',
        spinnerClass: 'loader-35'
    },
    {
        id: 32,
        heading: 'Loader 33',
        spinnerClass: 'loader-37'
    },
    {
        id: 33,
        heading: 'Loader 34',
        spinnerClass: 'loader-38'
    },
    {
        id: 34,
        heading: 'Loader 35',
        spinnerClass: 'loader-39'
    }
];

export const BasicColorData = [
    {
        id: 1,
        items: [
            { item: 'Action' },
            { item: 'Another Action' },
            { item: 'Something Else Here' },
            { divider: true },
            { item: 'Separated Link' }
        ],
        btnText: 'Action',
        btnclass: 'btn-secondary btn-square'
    },
    {
        id: 2,
        items: [
            { item: 'Action' },
            { item: 'Another Action' },
            { item: 'Something Else Here' },
            { divider: true },
            { item: 'Separated Link' }
        ],
        btnText: 'Action',
        btnclass: 'btn-success btn-square'
    },
    {
        id: 3,
        items: [
            { item: 'Action' },
            { item: 'Another Action' },
            { item: 'Something Else Here' },
            { divider: true },
            { item: 'Separated Link' }
        ],
        btnText: 'Action',
        btnclass: 'btn-info btn-square'
    },
    {
        id: 4,
        items: [
            { item: 'Action' },
            { item: 'Another Action' },
            { item: 'Something Else Here' },
            { divider: true },
            { item: 'Separated Link' }
        ],
        btnText: 'Action',
        btnclass: 'btn-warning btn-square',
    },
    {
        id: 5,
        items: [
            { item: 'Action' },
            { item: 'Another Action' },
            { item: 'Something Else Here' },
            { divider: true },
            { item: 'Separated Link' }
        ],
        btnText: 'Action',
        btnclass: 'btn-danger btn-square'
    }
];

export const RoundedData = [
    {
        id: 1,
        items: [
            { item: 'Action' },
            { item: 'Another Action' },
            { item: 'Something Else Here' },
            { item: 'Separated Link' }
        ],
        btnText: 'Secondary button ',
        btnclass: 'btn-round btn-secondary'
    },
    {
        id: 2,
        items: [
            { item: 'Action' },
            { item: 'Another Action' },
            { item: 'Something Else Here' },
            { item: 'Separated Link' }
        ],
        btnText: 'Success button ',
        btnclass: 'btn-round btn-success'
    },
    {
        id: 3,
        items: [
            { item: 'Action' },
            { item: 'Another Action' },
            { item: 'Something Else Here' },
            { item: 'Separated Link' }
        ],
        btnText: 'Info  button ',
        btnclass: 'btn-round btn-info'
    },
    {
        id: 4,
        items: [
            { item: 'Action' },
            { item: 'Another Action' },
            { item: 'Something Else Here' },
            { item: 'Separated Link' }
        ],
        btnText: 'Warning button ',
        btnclass: 'btn-round btn-warning'
    },
    {
        id: 5,
        items: [
            { item: 'Action' },
            { item: 'Another Action' },
            { item: 'Something Else Here' },
            { item: 'Separated Link' }
        ],
        btnText: 'Danger button ',
        btnclass: 'btn-round btn-danger'
    }
];

export const SplitData = [
    {
        id: 1,
        items: [
            { item: 'Link 1' },
            { item: 'Link 1' },
            { item: 'Link 1' }
        ],
        btnText: 'Secondary Button',
        btncolor: 'secondary'
    },
    {
        id: 2,
        items: [
            { item: 'Link 1' },
            { item: 'Link 1' },
            { item: 'Link 1' }
        ],
        btnText: 'Success Button',
        btncolor: 'success'
    },
    {
        id: 3,
        items: [
            { item: 'Link 1' },
            { item: 'Link 1' },
            { item: 'Link 1' }
        ],
        btnText: 'Info Button',
        btncolor: 'info'
    },
    {
        id: 4,
        items: [
            { item: 'Link 1' },
            { item: 'Link 1' },
            { item: 'Link 1' }
        ],
        btnText: 'Warning Button',
        btncolor: 'warning',
    },
    {
        id: 5,
        items: [
            { item: 'Link 1' },
            { item: 'Link 1' },
            { item: 'Link 1' }
        ],
        btnText: 'Danger Button',
        btncolor: 'danger'
    }
];