export const imageList =[
    {
        id:1,
        src:'lightgallry/08.jpg'
    },
    {
        id:2,
        src:'lightgallry/09.jpg'
    },
    {
        id:3,
        src:'lightgallry/010.jpg'
    },
    {
        id:4,
        src:'lightgallry/011.jpg'
    }
]