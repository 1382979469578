import React, { Fragment, useState, useEffect } from 'react';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import { EmailAddress, ForgotPassword, Password, RememberPassword, SignIn } from '../../../Constant';
import { Link, useNavigate } from 'react-router-dom';
import SocialAuth from './SocialAuth';
import FormHeader from './FormHeader';
import { toast } from 'react-toastify';

const LoginTab = () => {
    const [email, setEmail] = useState('test@gmail.com');
    const [password, setPassword] = useState('Admin@123');
    const [togglePassword, setTogglePassword] = useState(false);
    const navigate = useNavigate();

    const loginAuth = async (e) => {
        e.preventDefault();
        if (email === 'test@gmail.com' && password === 'Admin@123') {
            toast.success('Login Success...!')
            navigate(`${process.env.PUBLIC_URL}/dashboard/default`);
            localStorage.setItem('login', true);
        } else {
            toast.error('Please Enter valid email or password...!')
        }
    }
    return (
        <Fragment>
            <Form className="theme-form">
                <FormHeader />
                <FormGroup className='form-group'>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <Input className="form-control" type="email" onChange={e => setEmail(e.target.value)} defaultValue={'test@gmail.com'} />
                </FormGroup>
                <FormGroup className='form-group'>
                    <Label className="col-form-label">{Password}</Label>
                    <div className='form-input position-relative'>
                        <Input className="form-control" type={togglePassword ? 'text' : 'password'} onChange={e => setPassword(e.target.value)} defaultValue={'test123'} required />
                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                            <span className={togglePassword ? '' : 'show'}></span>
                        </div>
                    </div>
                </FormGroup>
                <FormGroup className="form-group mb-0">
                    <div className="checkbox p-0">
                        <Input id="checkbox1" type="checkbox" />
                        <Label className="text-muted" for="checkbox1">{RememberPassword}</Label>
                    </div>
                    <Link className="link" to={`${process.env.PUBLIC_URL}/pages/auth/forget-pwd`}>{ForgotPassword}</Link>
                </FormGroup>
                <div className='text-end mt-3'>
                    <Btn attrBtn={{ color: 'primary w-100', className: 'btn-block', onClick: (e) => loginAuth(e) }} >{SignIn}</Btn>
                </div>
                <SocialAuth />
            </Form>
        </Fragment>
    );
};
export default LoginTab;