// dashbaord
import Default from '../Components/Dashboard/Default';
import Ecommerce from '../Components/Dashboard/Ecommerce';

//widgets
import Chart from '../Components/Widgets/Chart';
import General from '../Components/Widgets/General';

//Page layout
import FooterDark from '../Components/PageLayout/FixedDark';
import FooterLight from '../Components/PageLayout/FixedLight';
import FooterFixed from '../Components/PageLayout/FootFixed';

// Ui-kits
import Typography from '../Components/UiKits/Typography';
import Avatars from '../Components/UiKits/Avtars';
import HelperClasses from '../Components/UiKits/HelperClass';
import Grid from '../Components/UiKits/Grid';
import TagAndPills from '../Components/UiKits/TagAndPills';
import Progressbars from '../Components/UiKits/Progress';
import Modals from '../Components/UiKits/Modals';
import Alerts from '../Components/UiKits/Alert';
import PopOvers from '../Components/UiKits/Popover';
import Tooltips from '../Components/UiKits/Tooltip';
import Spinners from '../Components/UiKits/Spinners';
import Dropdowns from '../Components/UiKits/Dropdown';
import Accordian from '../Components/UiKits/Accordian';
import TabBootstrap from '../Components/UiKits/Tabs';
import TabLine from '../Components/UiKits/Tabs/LineTab';
import Shadow from '../Components/UiKits/Shadows';
import List from '../Components/UiKits/Lists';

//Bonus-ui
import Scrollable from '../Components/Bonus-Ui/Scrollable';
import TreeView from '../Components/Bonus-Ui/TreeView';
import BootstrapNotify from '../Components/Bonus-Ui/BootstrapNotify';
import RatingClass from '../Components/Bonus-Ui/Rating';
import Dropzones from '../Components/Bonus-Ui/Dropzones';
import Tours from '../Components/Bonus-Ui/Tour';
import SweetAlerts from '../Components/Bonus-Ui/SweetAlert';
import Carousels from '../Components/Bonus-Ui/Carousel';
import Ribbons from '../Components/Bonus-Ui/Ribbons';
import Paginations from '../Components/Bonus-Ui/Pagination';
import Steps from '../Components/Bonus-Ui/Steps';
import BreadcrumbClass from '../Components/Bonus-Ui/Breadcrumb';
import RangeSlider from '../Components/Bonus-Ui/RangeSlider';
import Imagecropper from '../Components/Bonus-Ui/ImageCropper';
import Sticky from '../Components/Bonus-Ui/Sticky';
import DragAndDrop from '../Components/Bonus-Ui/DragAndDrop';
import UploadImage from '../Components/Bonus-Ui/ImageUpload';
import BasicCards from '../Components/Bonus-Ui/Cards/BasicCards/index';
import CreativeCards from '../Components/Bonus-Ui/Cards/CreativeCards';
import TabCard from '../Components/Bonus-Ui/Cards/TabCard';
import DraggingCards from '../Components/Bonus-Ui/Cards/DragCards/index';
import Timeline from '../Components/Bonus-Ui/Timeline/Timeline';

// Icons
import WeatherIcon from '../Components/Icons/WeatherIcon';
import FeatherIcon from '../Components/Icons/FeatherIcon';
import ThemifyIcon from '../Components/Icons/ThemifyIcon';
import IcoIcon from '../Components/Icons/IcoIcon';
import FontawesomeIcon from '../Components/Icons/FontawesomeIcon';
import FlagIcon from '../Components/Icons/FlagIcon';

//chart
import ApexCharts from '../Components/Charts/ApexCharts';
import ChartJs from '../Components/Charts/ChartsJs';
import GoogleCharts from '../Components/Charts/GoogleCharts';


// Buttons
import DefaultButton from '../Components/Buttons/Default';
import GroupButton from '../Components/Buttons/GroupBtn/index';

//forms
import FormValidation from '../Components/Forms/FormControl/FormValidation';
import BaseInput from '../Components/Forms/FormControl/BaseInput';
import CheckboxandRadio from '../Components/Forms/FormControl/CheckRadio';
import InputGroups from '../Components/Forms/FormControl/InputGroups';
import MegaOption from '../Components/Forms/FormControl/MegaOption';
import Datepicker from '../Components/Forms/FormWidget/DatePicker';
import DateTimeContain from '../Components/Forms/FormWidget/DateandTime'
import TypeaheadComp from '../Components/Forms/FormWidget/TypeaheadComponent/Typeahead';
import FormDateRangepickerContain from '../Components/Forms/FormWidget/FormDateRangePicker'
import FormSelectContain from '../Components/Forms/FormWidget/FormSelect2';
import FormSwitchContain from '../Components/Forms/FormWidget/FormSwitch';
import FormClipboardContain from '../Components/Forms/FormWidget/FormClipboard';
import FormTouchspinContain from '../Components/Forms/FormWidget/FormTouchspin';

import FormDefault from '../Components/Forms/FormLayout/Default';
import FormWizard1 from '../Components/Forms/FormLayout/FormWizard1';
import FormWizard2 from '../Components/Forms/FormLayout/FormWizard2';
import FormWizard3 from '../Components/Forms/FormLayout/FormWizard3';

//Table
import BasicTables from '../Components/Tables/Reactstrap/BasicTable';
import DataTables from '../Components/Tables/DataTable';

//Application
import Newproject from '../Components/App/Project/Newproject';
import Project from '../Components/App/Project/Project';
//chat
import Chat from '../Components/App/Chat/ChatApp';
import VideoChat from '../Components/App/Chat/VideoChat';
//FileManager
import FileManager from '../Components/App/FileManager/index'
//Email
import MailInboxContain from '../Components/App/Email/MailInbox';
import ComposeContain from '../Components/App/Email/Compose';
import ReadMailContain from '../Components/App/Email/ReadMail';
//calender
import CalenderContain from '../Components/App/Calender/Index';
import DraggableContain from '../Components/App/Calender/DraggableCalender';
//User app
import UsersCardssContain from '../Components/App/Users/UsersCards';
import UsersEditContain from '../Components/App/Users/UsersEdit';
import UsersProfileContain from '../Components/App/Users/UsersProfile';
//E-commerce
import Products from '../Components/App/Ecommerce/Products';
import ProductCart from '../Components/App/Ecommerce/ProductCart';
import AddProduct from '../Components/App/Ecommerce/AddProduct';
import WishList from '../Components/App/Ecommerce/Wishlist';
import CheckOut from '../Components/App/Ecommerce/CheckOut';
import Invoice from '../Components/App/Ecommerce/Invoice';
import OrderHistory from '../Components/App/Ecommerce/OrderHistory';
import ProductPage from '../Components/App/Ecommerce/ProductPage';
import PricingMemberShip from '../Components/App/Ecommerce/PricingMemberShip';
import PaymentDetails from '../Components/App/Ecommerce/PaymentDetails';
import ProductListContain from '../Components/App/Ecommerce/ProductList';
//contact
import Contact from '../Components/App/Contact/ContactApp';
//Book mark
import BookmarksContain from '../Components/App/BookMarks';
//Task
import Task from '../Components/App/Task';
//social app
import SocialAppContain from '../Components/App/SocialApp';
//Todo
import TodoContain from '../Components/App/Todo';
//search-result
import SearchResultContain from '../Components/App/SearchResult';
//Gallary
import ImageGallery from '../Components/Gallery/ImageGallery';
import MesonryDesc from '../Components/Gallery/Mesonry/MesonryDesc';
import MasonryGallery from '../Components/Gallery/Mesonry/MesonryGallery';
import ImageWithDesc from '../Components/Gallery/ImageWithDesc';
import ImageHover from '../Components/Gallery/ImageHover';
//Blog
import BlogDetail from '../Components/Blog/BlogDetail';
import BlogPost from '../Components/Blog/BlogPost';
import BlogSingle from '../Components/Blog/BlogSingle';

//Job Search
import JobCards from '../Components/JobSearch/JobCardView';
import ListView from '../Components/JobSearch/ListView';
import JobDetails from '../Components/JobSearch/JobDetail';
import Apply from '../Components/JobSearch/Apply';

//Learning
import LearningList from '../Components/Learning/LearningList';
import DetailCourse from '../Components/Learning/DetailCourse';

//Map
import GoogleMaps from '../Components/Maps/GoogleMap';
import LeafletMapContain from '../Components/Maps/Leafletmap';

//editor
import CkEditorContain from '../Components/Editor/CkEditor';
import MdeEDitorContain from '../Components/Editor/MDEEditor/MDEEditor';
import ACEEditorContain from '../Components/Editor/AceCodeEditor/ACECodeEditor';
//Faq
import FAQContent from '../Components/FAQ';
//Knowledegbase
import KnowledgeBaseContain from '../Components/Knowledgebase/KnowledgeBase';
import KnowledgeCategoryContain from '../Components/Knowledgebase/KnowledgeCategory';
import KnowledgeDetailContain from '../Components/Knowledgebase/KnowledgeDetails';
//support ticket
import SupportTickit from '../Components/SupportTickit';

//sample page
import SamplePage from '../Components/PageLayout/SimplePage';

export const routes = [
        //dashboard
        { path: `${process.env.PUBLIC_URL}/dashboard/default/`, Component: <Default /> },
        { path: `${process.env.PUBLIC_URL}/dashboard/ecommerce/`, Component: <Ecommerce /> },

        //widgets
        { path: `${process.env.PUBLIC_URL}/widgets/general/`, Component: <General /> },
        { path: `${process.env.PUBLIC_URL}/widgets/chart/`, Component: <Chart /> },

        //PageLayout
        { path: `${process.env.PUBLIC_URL}/page-layout/footer-dark`, Component: <FooterDark /> },
        { path: `${process.env.PUBLIC_URL}/page-layout/footer-fixed`, Component: <FooterFixed /> },
        { path: `${process.env.PUBLIC_URL}/page-layout/footer-light`, Component: <FooterLight /> },

        //Ui-kit
        { path: `${process.env.PUBLIC_URL}/ui-kits/typograph`, Component: <Typography /> },
        { path: `${process.env.PUBLIC_URL}/ui-kits/avatars`, Component: <Avatars /> },
        { path: `${process.env.PUBLIC_URL}/ui-kits/helper-classes`, Component: <HelperClasses /> },
        { path: `${process.env.PUBLIC_URL}/ui-kits/grid`, Component: <Grid /> },
        { path: `${process.env.PUBLIC_URL}/ui-kits/tag-pills`, Component: <TagAndPills /> },
        { path: `${process.env.PUBLIC_URL}/ui-kits/progress`, Component: <Progressbars /> },
        { path: `${process.env.PUBLIC_URL}/ui-kits/modal`, Component: <Modals /> },
        { path: `${process.env.PUBLIC_URL}/ui-kits/alert`, Component: <Alerts /> },
        { path: `${process.env.PUBLIC_URL}/ui-kits/popover`, Component: <PopOvers /> },
        { path: `${process.env.PUBLIC_URL}/ui-kits/tooltip`, Component: <Tooltips /> },
        { path: `${process.env.PUBLIC_URL}/ui-kits/spinners`, Component: <Spinners /> },
        { path: `${process.env.PUBLIC_URL}/ui-kits/dropdown`, Component: <Dropdowns /> },
        { path: `${process.env.PUBLIC_URL}/ui-kits/accordion`, Component: <Accordian /> },
        { path: `${process.env.PUBLIC_URL}/ui-kits/tab-bootstrap`, Component: <TabBootstrap /> },
        { path: `${process.env.PUBLIC_URL}/ui-kits/line-tabs`, Component: <TabLine /> },
        { path: `${process.env.PUBLIC_URL}/ui-kits/shadow`, Component: <Shadow /> },
        { path: `${process.env.PUBLIC_URL}/ui-kits/lists`, Component: <List /> },

        //Bonus-ui
        { path: `${process.env.PUBLIC_URL}/bonus-ui/scrollable`, Component: <Scrollable /> },
        { path: `${process.env.PUBLIC_URL}/bonus-ui/tree-view`, Component: <TreeView /> },
        { path: `${process.env.PUBLIC_URL}/bonus-ui/bootstrap-notify`, Component: <BootstrapNotify /> },
        { path: `${process.env.PUBLIC_URL}/bonus-ui/rating`, Component: <RatingClass /> },
        { path: `${process.env.PUBLIC_URL}/bonus-ui/dropzone`, Component: <Dropzones /> },
        { path: `${process.env.PUBLIC_URL}/bonus-ui/tour`, Component: <Tours /> },
        { path: `${process.env.PUBLIC_URL}/bonus-ui/sweet-alert`, Component: <SweetAlerts /> },
        { path: `${process.env.PUBLIC_URL}/bonus-ui/carousel`, Component: <Carousels /> },
        { path: `${process.env.PUBLIC_URL}/bonus-ui/ribbons`, Component: <Ribbons /> },
        { path: `${process.env.PUBLIC_URL}/bonus-ui/pagination`, Component: <Paginations /> },
        { path: `${process.env.PUBLIC_URL}/bonus-ui/steps`, Component: <Steps /> },
        { path: `${process.env.PUBLIC_URL}/bonus-ui/breadcrumb`, Component: <BreadcrumbClass /> },
        { path: `${process.env.PUBLIC_URL}/bonus-ui/rangeSlider`, Component: <RangeSlider /> },
        { path: `${process.env.PUBLIC_URL}/bonus-ui/imageCropper`, Component: <Imagecropper /> },
        { path: `${process.env.PUBLIC_URL}/bonus-ui/stickyNotes`, Component: <Sticky /> },
        { path: `${process.env.PUBLIC_URL}/bonus-ui/dragNDropComp`, Component: <DragAndDrop /> },
        { path: `${process.env.PUBLIC_URL}/bonus-ui/image-upload`, Component: <UploadImage /> },
        { path: `${process.env.PUBLIC_URL}/bonus-ui/card/basicCards`, Component: <BasicCards /> },
        { path: `${process.env.PUBLIC_URL}/bonus-ui/card/creativeCards`, Component: <CreativeCards /> },
        { path: `${process.env.PUBLIC_URL}/bonus-ui/card/tabCard`, Component: <TabCard /> },
        { path: `${process.env.PUBLIC_URL}/bonus-ui/card/draggingCards`, Component: <DraggingCards /> },
        { path: `${process.env.PUBLIC_URL}/bonus-ui/timelines/timeline`, Component: <Timeline /> },

        //Icons
        { path: `${process.env.PUBLIC_URL}/icons/flagIcons`, Component: <FlagIcon /> },
        { path: `${process.env.PUBLIC_URL}/icons/fontAwsomeIcon`, Component: <FontawesomeIcon /> },
        { path: `${process.env.PUBLIC_URL}/icons/icoIcons`, Component: <IcoIcon /> },
        { path: `${process.env.PUBLIC_URL}/icons/themifyIcons`, Component: <ThemifyIcon /> },
        { path: `${process.env.PUBLIC_URL}/icons/featherIcons`, Component: <FeatherIcon /> },
        { path: `${process.env.PUBLIC_URL}/icons/weatherIcons`, Component: <WeatherIcon /> },

        //Buttons
        { path: `${process.env.PUBLIC_URL}/buttons/default-btn`, Component: <DefaultButton /> },
        { path: `${process.env.PUBLIC_URL}/buttons/groupBtn`, Component: <GroupButton /> },

        //Charts
        { path: `${process.env.PUBLIC_URL}/charts/apexCharts`, Component: <ApexCharts /> },
        { path: `${process.env.PUBLIC_URL}/charts/googleChart`, Component: <GoogleCharts /> },
        { path: `${process.env.PUBLIC_URL}/charts/chartJs`, Component: <ChartJs /> },

        // Forms
        { path: `${process.env.PUBLIC_URL}/forms/formValidation`, Component: <FormValidation /> },
        { path: `${process.env.PUBLIC_URL}/forms/baseInput`, Component: <BaseInput /> },
        { path: `${process.env.PUBLIC_URL}/forms/radio-checkbox`, Component: <CheckboxandRadio /> },
        { path: `${process.env.PUBLIC_URL}/forms/inputGroup`, Component: <InputGroups /> },
        { path: `${process.env.PUBLIC_URL}/forms/megaOptions`, Component: <MegaOption /> },

        { path: `${process.env.PUBLIC_URL}/form-widget/datepicker`, Component: <Datepicker /> },
        { path: `${process.env.PUBLIC_URL}/form-widget/datetimepicker`, Component: <DateTimeContain /> },
        { path: `${process.env.PUBLIC_URL}/form-widget/typeahead`, Component: <TypeaheadComp /> },
        { path: `${process.env.PUBLIC_URL}/form-widget/rangepicker`, Component: <FormDateRangepickerContain /> },
        { path: `${process.env.PUBLIC_URL}/form-widget/select2`, Component: <FormSelectContain /> },
        { path: `${process.env.PUBLIC_URL}/form-widget/switch`, Component: <FormSwitchContain /> },
        { path: `${process.env.PUBLIC_URL}/form-widget/touchspin`, Component: <FormTouchspinContain /> },
        { path: `${process.env.PUBLIC_URL}/form-widget/clipboard`, Component: <FormClipboardContain /> },

        { path: `${process.env.PUBLIC_URL}/form-layout/formDefault`, Component: <FormDefault /> },
        { path: `${process.env.PUBLIC_URL}/form-layout/formWizard1`, Component: <FormWizard1 /> },
        { path: `${process.env.PUBLIC_URL}/form-layout/formWizard2`, Component: <FormWizard2 /> },
        { path: `${process.env.PUBLIC_URL}/form-layout/formWizard3`, Component: <FormWizard3 /> },

        //Table
        { path: `${process.env.PUBLIC_URL}/table/basic`, Component: <BasicTables /> },
        { path: `${process.env.PUBLIC_URL}/table/data-table`, Component: <DataTables /> },

        //Applicatiion
        { path: `${process.env.PUBLIC_URL}/app/project/project-list`, Component: <Project /> },
        { path: `${process.env.PUBLIC_URL}/app/project/new-project`, Component: <Newproject /> },

        //chat
        { path: `${process.env.PUBLIC_URL}/app/chat-app`, Component: <Chat /> },
        { path: `${process.env.PUBLIC_URL}/app/chat-video-app`, Component: <VideoChat /> },
        //FileManager
        { path: `${process.env.PUBLIC_URL}/app/file-manager`, Component: <FileManager /> },
        //email
        { path: `${process.env.PUBLIC_URL}/app/email/mailbox`, Component: <MailInboxContain /> },
        { path: `${process.env.PUBLIC_URL}/app/email/readmail`, Component: <ReadMailContain /> },
        { path: `${process.env.PUBLIC_URL}/app/email/compose`, Component: <ComposeContain /> },
        //Calender
        { path: `${process.env.PUBLIC_URL}/app/calendar/basic-calendar`, Component: <CalenderContain /> },
        { path: `${process.env.PUBLIC_URL}/app/calendar/draggable-calendar`, Component: <DraggableContain /> },
        //Users
        { path: `${process.env.PUBLIC_URL}/app/users/userProfile`, Component: <UsersProfileContain /> },
        { path: `${process.env.PUBLIC_URL}/app/users/userEdit`, Component: <UsersEditContain /> },
        { path: `${process.env.PUBLIC_URL}/app/users/userCards`, Component: <UsersCardssContain /> },
        //Ecommerce
        { path: `${process.env.PUBLIC_URL}/app/ecommerce/product`, Component: <Products /> },
        { path: `${process.env.PUBLIC_URL}/app/ecommerce/product-page/:id`, Component: <ProductPage /> },
        { path: `${process.env.PUBLIC_URL}/app/ecommerce/add-product`, Component: <AddProduct /> },
        { path: `${process.env.PUBLIC_URL}/app/ecommerce/payment-details`, Component: <PaymentDetails /> },
        { path: `${process.env.PUBLIC_URL}/app/ecommerce/orderhistory`, Component: <OrderHistory /> },
        { path: `${process.env.PUBLIC_URL}/app/ecommerce/invoice`, Component: <Invoice /> },
        { path: `${process.env.PUBLIC_URL}/app/ecommerce/cart`, Component: <ProductCart /> },
        { path: `${process.env.PUBLIC_URL}/app/ecommerce/wishlist`, Component: <WishList /> },
        { path: `${process.env.PUBLIC_URL}/app/ecommerce/checkout`, Component: <CheckOut /> },
        { path: `${process.env.PUBLIC_URL}/app/ecommerce/pricing`, Component: <PricingMemberShip /> },
        { path: `${process.env.PUBLIC_URL}/app/ecommerce/product-list`, Component: <ProductListContain /> },
        //Contact
        { path: `${process.env.PUBLIC_URL}/app/contact`, Component: <Contact /> },
        //book-mark
        { path: `${process.env.PUBLIC_URL}/app/bookmark`, Component: <BookmarksContain /> },
        //task
        { path: `${process.env.PUBLIC_URL}/app/task`, Component: <Task /> },
        //SocialApp
        { path: `${process.env.PUBLIC_URL}/app/social-app`, Component: <SocialAppContain /> },
        //Todo
        { path: `${process.env.PUBLIC_URL}/app/todo`, Component: <TodoContain /> },
        //search-result
        { path: `${process.env.PUBLIC_URL}/app/search-result`, Component: <SearchResultContain /> },

        //Gallery
        { path: `${process.env.PUBLIC_URL}/app/gallery/imageGallery`, Component: <ImageGallery /> },
        { path: `${process.env.PUBLIC_URL}/app/gallery/imageWithDesc`, Component: <ImageWithDesc /> },
        { path: `${process.env.PUBLIC_URL}/app/gallery/mesonryGallery`, Component: <MasonryGallery /> },
        { path: `${process.env.PUBLIC_URL}/app/gallery/mesonryDesc`, Component: <MesonryDesc /> },
        { path: `${process.env.PUBLIC_URL}/app/gallery/imageHover`, Component: <ImageHover /> },
        //Blog
        { path: `${process.env.PUBLIC_URL}/app/blog/blogDetail`, Component: <BlogDetail /> },
        { path: `${process.env.PUBLIC_URL}/app/blog/blogPost`, Component: <BlogPost /> },
        { path: `${process.env.PUBLIC_URL}/app/blog/blogSingle`, Component: <BlogSingle /> },

        //Job Search
        { path: `${process.env.PUBLIC_URL}/app/jobSearch/cardView`, Component: <JobCards /> },
        { path: `${process.env.PUBLIC_URL}/app/jobSearch/job-list`, Component: <ListView /> },
        { path: `${process.env.PUBLIC_URL}/app/jobSearch/job-detail`, Component: <JobDetails /> },
        { path: `${process.env.PUBLIC_URL}/app/jobSearch/job-apply`, Component: <Apply /> },

        //Learning
        { path: `${process.env.PUBLIC_URL}/app/learning/learning-list`, Component: <LearningList /> },
        { path: `${process.env.PUBLIC_URL}/app/learning/learning-detail`, Component: <DetailCourse /> },

        //Map
        { path: `${process.env.PUBLIC_URL}/app/map/googleMap`, Component: <GoogleMaps /> },
        { path: `${process.env.PUBLIC_URL}/app/map/leaflet-map`, Component: <LeafletMapContain /> },

        //Editor
        { path: `${process.env.PUBLIC_URL}/editor/ckEditor`, Component: <CkEditorContain /> },
        { path: `${process.env.PUBLIC_URL}/editor/mdeEditor`, Component: <MdeEDitorContain /> },
        { path: `${process.env.PUBLIC_URL}/editor/ace-code-editor`, Component: <ACEEditorContain /> },

        //Knowledge
        { path: `${process.env.PUBLIC_URL}/app/knowledgebase`, Component: <KnowledgeBaseContain /> },
        { path: `${process.env.PUBLIC_URL}/app/knowledgebase-category`, Component: <KnowledgeCategoryContain /> },
        { path: `${process.env.PUBLIC_URL}/app/knowledgebase-detail`, Component: <KnowledgeDetailContain /> },

        //Support Ticket
        { path: `${process.env.PUBLIC_URL}/app/support-ticket`, Component: <SupportTickit /> },

        //Faq
        { path: `${process.env.PUBLIC_URL}/app/faq`, Component: <FAQContent /> },

        //sample-page
        { path: `${process.env.PUBLIC_URL}/pages/sample-page`, Component: <SamplePage /> },
];
