import React from 'react';
import { Col } from 'reactstrap';
import { MarginLeft } from '../../../../../Constant';
import CommonHeaderClass from '../../Common/CommonHeaderClass';

const MarginLeftCard = () => {
  return (
    <Col lg="4" md="6">
      <CommonHeaderClass title={MarginLeft}>{`.m-l-0 {
          margin-left: 0px !important;
          }
          .m-l-5 {
          margin-left: 5px !important;
          }
          .m-l-10 {
          margin-left: 10px !important;
          }
          .m-l-15 {
          margin-left: 15px !important;
          }
          .m-l-20 {
          margin-left: 20px !important;
          }
          .m-l-25 {
          margin-left: 25px !important;
          }
          .m-l-30 {
          margin-left: 30px !important;
          }
          .m-l-35 {
          margin-left: 35px !important;
          }
          .m-l-40 {
          margin-left: 40px !important;
          }
          .m-l-45 {
          margin-left: 45px !important;
          }
          .m-l-50 {
          margin-left: 50px !important;
          }
        `}
      </CommonHeaderClass>
    </Col>

  );
};

export default MarginLeftCard;