import React from 'react';
import { Col } from 'reactstrap';
import { MarginTop } from '../../../../../Constant';
import CommonHeaderClass from '../../Common/CommonHeaderClass';

const MarginTopCard = () => {
  return (
    <Col lg="4" md="6">
      <CommonHeaderClass title={MarginTop}>{`.m-t-0 {
          margin-top: 0px !important;
          }
          .m-t-5 {
          margin-top: 5px !important;
          }
          .m-t-10 {
          margin-top: 10px !important;
          }
          .m-t-15 {
          margin-top: 15px !important;
          }
          .m-t-20 {
          margin-top: 20px !important;
          }
          .m-t-25 {
          margin-top: 25px !important;
          }
          .m-t-30 {
          margin-top: 30px !important;
          }
          .m-t-35 {
          margin-top: 35px !important;
          }
          .m-t-40 {
          margin-top: 40px !important;
          }
          .m-t-45 {
          margin-top: 45px !important;
          }
          .m-t-50 {
          margin-top: 50px !important;
          }
        `}
      </CommonHeaderClass>
    </Col>

  );
};

export default MarginTopCard;