export const BlogData = [
    {
        id: 1,
        img: 'blog/blog-5.jpg',
        admin: 'Admin',
        para: 'Dressing is a way of life.',
        desc: 'A huge part of it is the incomparable you can encounter every day. People just do anymore profound.',
        comment: '5',
        hits: '2 Hits',
        details: 'Perspiciatis unde omnis iste natus error sit.Dummy text'
    },
    {
        id: 2,
        img: 'blog/blog-6.jpg',
        admin: 'Admin',
        para: 'Encounter every day.',
        desc: 'People just dont do it anymore. We have to change that. Sometimes the simplest things are the most profound.',
        comment: '5',
        hits: '2 Hits',
        details: 'Perspiciatis unde omnis iste natus error sit.Dummy text'
    },
    {
        id: 3,
        img: 'blog/blog-5.jpg',
        admin: 'Admin',
        para: 'Dressing is a way of life.',
        desc: 'A huge part of it is the incomparable you can encounter every day. People just do anymore profound.',
        comment: '5',
        hits: '2 Hits',
        details: 'Perspiciatis unde omnis iste natus error sit.Dummy text'
    },
    {
        id: 4,
        img: 'blog/blog-6.jpg',
        admin: 'Admin',
        para: 'Encounter every day.',
        desc: 'We have to change that. Sometimes the simplest things are the most profound. People just dont do it anymore.',
        comment: '5',
        hits: '2 Hits',
        details: 'Perspiciatis unde omnis iste natus error sit.Dummy text'
    }
];

export const BlogSingleData = [
    {
        id: 1,
        name: 'Jolio Mark',
        post: 'Designer',
        hits: '04 Hits',
        img:'comment.jpg',
        comments: '392 Comments',
        para: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.`
    },
    {
        id: 2,
        name: 'Helsenky Roi',
        post: 'App Developer',
        hits: '02 Hits',
        img:'9.jpg',
        comments: '410 Comments',
        para: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.`
    },
    {
        id: 3,
        name: 'Rio Martin',
        post: 'UI Designer',
        hits: '08 Hits',
        img:'4.jpg',
        comments: '580 Comments',
        para: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.`
    },
    {
        id: 4,
        name: 'Jack Helson',
        post: 'UX Designer',
        hits: '12 Hits',
        img:'12.png',
        comments: '185 Comments',
        para: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.`
    },
    {
        id: 5,
        name: 'Addy Mark',
        post: 'Web Developer',
        hits: '09 Hits',
        img:'14.png',
        comments: '620 Comments',
        para: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.`
    }
];