import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import Chart from 'react-apexcharts';
import { MonthlySale } from '../../../Data/Widgets/Chart/Chart';
import HeaderCard from '../../Common/Component/HeaderCard';
import { MonthlySales } from '../../../Constant';

const MonthlySaleChart = () => {
    return (
        <Col lg="5" className="xl-50 box-col-5">
            <div className="small-chart-widget chart-widgets-small">
                <Card>
                    <HeaderCard title={MonthlySales} setting={true} />
                    <CardBody>
                        <div className="chart-container">
                            <Row>
                                <Col>
                                    <div id="chart-widget8">
                                        <Chart options={MonthlySale.options} series={MonthlySale.series} height="300" type="radar" />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </Col>
    );
};

export default MonthlySaleChart;